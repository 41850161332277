























import { Component, Vue, Prop } from 'vue-property-decorator';
import { TelemetryIndex } from '@/types/telemetry';
import TimeMeter from '../../dataDisplayComponents/TimeMeter.vue';
import ClassicMeter from '../../dataDisplayComponents/ClassicMeter.vue';

@Component({
  components: {
    TimeMeter,
    ClassicMeter
  }
})
/**
 * @group Analytics Card
 * Card with time meters
 */
export default class TimeMetersCard extends Vue {
  @Prop(Array) readonly telemetries!: TelemetryIndex[]

  @Prop(Array) readonly kobizeTelemetries!: TelemetryIndex[]
}

