var render = function (_h,_vm) {var _c=_vm._c;return _c('div',{staticClass:"c-device-status-indices"},[(_vm.props.errorStatus)?_c('BaseTooltip',{attrs:{"content":_vm.props.errorStatus.tooltip,"position":"top"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({class:{
        'c-device-status-indices__error': true,
        'c-device-status-indices__error--show': _vm.props.errorStatus.value
      },attrs:{"size":"1.6rem","color":"red"}},on),[_vm._v(" mdi-alert ")])]}}],null,false,134958507)}):_vm._e(),(_vm.props.powerStatus)?_c('BaseTooltip',{attrs:{"content":_vm.props.powerStatus.tooltip,"position":"top"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"c-device-status-indices__power",attrs:{"size":"1.6rem","color":_vm.props.powerStatus.value ? 'orange' : 'grey'}},on),[_vm._v(" "+_vm._s(_vm.props.powerStatus.value ? "mdi-lightbulb-on-outline" : "mdi-lightbulb-outline")+" ")])]}}],null,false,2917984965)}):_vm._e(),(_vm.props.onlineStatus)?_c('BaseTooltip',{attrs:{"content":_vm.props.onlineStatus.tooltip,"extra":_vm.props.onlineStatus.value ? null : _vm.props.lastConnection,"position":"top"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({staticClass:"c-device-status-indices__connection",class:{
        'c-device-status-indices__connection--is-online': _vm.props.onlineStatus.value,
      }},on))]}}],null,false,2241508760)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }