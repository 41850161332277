

















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
/**
 * @group Analytics Card
 * Time meter displaying time elapsed in _h_m_s format
 */
export default class TimeMeter extends Vue {
  // time elapsed in miliseconds
  @Prop({ type: Number, required: true }) readonly elapsed!: number

  @Prop({ type: String, required: false }) readonly content!: string

  secondsToHMSString(totalTimeInSeconds: number) {
    let seconds = totalTimeInSeconds;
    const hours = Math.floor(seconds / 60 / 60);
    seconds -= hours * 60 * 60;
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  }
}
