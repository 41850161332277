











































import Vue, { PropType } from 'vue';

interface IndexData {
  value: boolean,
  tooltip: string
}

export default Vue.extend({
  functional: true,
  name: 'DeviceStatusIndices',
  props: {
    errorStatus: {
      type: Object as PropType<IndexData>,
    },
    powerStatus: {
      type: Object as PropType<IndexData>,
    },
    onlineStatus: {
      type: Object as PropType<IndexData>,
    },
    lastConnection: {
      type: String,
    },
  },
});
