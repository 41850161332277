

































import { MinMaxTelemetryIndex } from '@/types/telemetry';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
/**
 * @group Analytics Card
 * Gauge for displaying value within min and max range
 */
export default class SemiCircleGauge extends Vue {
  @Prop({ required: true, type: Object }) readonly metricData!: MinMaxTelemetryIndex

  radius = 50;

  stroke = 14;

  showProgress: boolean = false;

  mounted() {
    setTimeout(() => {
      this.showProgress = true;
    }, 500);
  }

  get progress() {
    if (this.metricData.value && this.metricData.max && this.showProgress) {
      const progress = (this.metricData.value - this.metricData.min) / (this.metricData.max - this.metricData.min);
      return Math.min(progress, 1);
    }
    return 0;
  }

  get radiusNormalized() {
    return this.radius - this.stroke / 2;
  }

  get path() {
    return `M ${this.stroke / 2} ${this.radius}
            A ${this.radiusNormalized} ${this.radiusNormalized} 0 0 1
            ${2 * this.radius - (this.stroke / 2)} ${this.radius}`;
  }

  get valueInRange() {
    if (this.metricData.value !== null) {
      return this.metricData.value >= this.metricData.min && this.metricData.value <= this.metricData.max;
    }
    return true;
  }

  get circumference() {
    return 2 * Math.PI * this.radiusNormalized;
  }

  get strokeDashArray() {
    return `${this.circumference / 2} ${this.circumference / 2}`;
  }

  get strokeDashOffset() {
    return this.progress !== null ? (this.circumference / 2) * (1 - this.progress) : null;
  }

  floorNumTo2Decimal(value: number) {
    return Math.floor(value * 100) / 100;
  }
}
